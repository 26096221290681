import {Grid, Typography} from '@mui/material';
import {Button} from '@finpay-development/shared-components';
import React, {useState} from 'react';

export const EnrollmentThankYouView = (props: {
  clientDetails: {
    surveyUrl: string,
    clientName: string,
    email: string,
    phoneNumber: string
  }
}) => {

  const {clientDetails: {surveyUrl, clientName, email, phoneNumber}} = props;

  const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);

  return (
      <>
        <Grid item xs={12} sx={{marginTop: '3rem'}}>
          <Typography test-id="enrollment-page-thank-you">
            Thank you for enrolling! <br/>
            Your feedback is important to us.
          </Typography>
        </Grid>
        {
            surveyUrl && (
                <div test-id="enrollment-page-survey">
                  <Grid item xs={12} sx={{marginTop: '3rem'}}>
                    <Typography>
                      Please click below to take a short 5-question survey about
                      your experience to help us better serve you in the future.
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="center" sx={{marginTop: '3rem'}}>
                    <Button
                        test-id="enrollment-page-survey-btn"
                        spinnerLeftPosition={9}
                        loading={isFormProcessing}
                        disabled={isFormProcessing}
                        onClick={() => {
                          setIsFormProcessing(true);
                          window.location.href = `https://www.surveymonkey.com/r/${surveyUrl}`
                        }}
                    >
                      Complete Survey
                    </Button>
                  </Grid>
                </div>
            )
        }
        <Grid item xs={12} sx={{marginTop: '3rem'}}>
          <Typography>
            For help or questions, please contact {clientName} at {email} or {phoneNumber}
          </Typography>
        </Grid>
      </>
  );

};
import {getRateAndLosFromQuoteMethod} from '@finpay-development/shared-components';
import {
    ConfigRiskThreshold,
    RiskThresholdSymbols,
} from '../../admin-configuration/models/risk-threshold';
import {
    FinPayRiskClass,
    ConfigRiskClassSetting,
} from '../../admin-configuration/models/risk-class-setting';
import {
    EstLevelOfCare,
    EstimatorBody,
    EditEstLevelOfCare,
    PFRSummary,
    emptyPFRSummary,
    RatesLos,
    PriorCarePFR,
    CrossoverPolicies,
} from '../models/estimator';
import {
    ThresholdEnum,
    RiskStatus,
    RiskAssessmentForm,
    FacilityRiskThreshold,
    MultiFacilityRiskThreshold,
    RiskAssessmentEstimate,
    FacilityRiskAssessment,
    emptyFacilityRiskAssessment,
    ValueRisk,
    PfrEiv,
    PayerClaimRate,
    ClientRiskClassSettings,
    FacilityRiskClassSettings,
    MultiFacilityRiskClassSetting,
    ClientDeficiencySettings,
    PatientEpisodeMarginSettings,
    ReferralSourceRevenue,
    EditedFacilityCensus,
    AdmitStatus,
	newEmptyFacilityRiskAssessment,
} from '../models/risk-assessment';
import {ImplementationFacility} from '../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import {SelectedFacilityReferralSource} from '../../implementation-specialist/models/referral-source';
import {Vob, vobClassificationsItem} from '../models/vob';
import {Utils} from '../../shared/utils';
import {admissionsAdvisorUtils} from './admission-advisor-utils';
import {ClientRiskAssessmentFields} from 'src/shared/model/client-risk-assessment-config';
import {
    CrossoverSelectionEnum,
    Estimate,
    EstimateSummary,
    FacilityLevelOfCare,
    QuoteMethod,
    FacilityRiskAssessment as NewFacilityRiskAssessment,
    ValueRisk as NewValueRisk,
    RiskStatus as NewRiskStatus,
    AdmitStatus as NewAdmitStatus,
} from '@finpay/estimation-types';
import {FormikProps} from 'formik';
import {RiskAssessmentFormikValues} from '../components/risk-assessment/new-risk-assessment-main';

class RiskAssessmentUtils {
    mergeConfigFacilityRiskThresholds = (
        configRiskThresholds: ConfigRiskThreshold[],
        facilityRiskThresholds: FacilityRiskThreshold[],
        facilityId: number
    ): FacilityRiskThreshold[] => {
        let mergedRiskThresholds: FacilityRiskThreshold[] = [];
        configRiskThresholds?.forEach(
            (configThreshold: ConfigRiskThreshold) => {
                let thisFacilityThreshold = facilityRiskThresholds?.find(
                    el =>
                        el.cfgRiskThresholdId ===
                        configThreshold.riskThresholdId
                );
                let thisMergedThreshold: FacilityRiskThreshold = {
                    facilityRiskThresholdId: null,
                    clientFacilityId: facilityId,
                    cfgRiskThresholdId: null,
                    cfgRiskThresholdCode: null,
                    lowRisk: null,
                    mediumRisk: null,
                    highRisk: null,
                };
                if (thisFacilityThreshold) {
                    thisMergedThreshold.facilityRiskThresholdId =
                        thisFacilityThreshold.facilityRiskThresholdId;
                    thisMergedThreshold.cfgRiskThresholdId =
                        configThreshold.riskThresholdId;
                    thisMergedThreshold.cfgRiskThresholdCode =
                        configThreshold.riskThresholdCode;
                    thisMergedThreshold.lowRisk = thisFacilityThreshold.lowRisk
                        ? thisFacilityThreshold.lowRisk
                        : configThreshold.lowRiskDefault;
                    thisMergedThreshold.mediumRisk =
                        thisFacilityThreshold.mediumRisk
                            ? thisFacilityThreshold.mediumRisk
                            : configThreshold.mediumRiskDefault;
                    thisMergedThreshold.highRisk =
                        thisFacilityThreshold.highRisk
                            ? thisFacilityThreshold.highRisk
                            : configThreshold.highRiskDefault;
                } else {
                    thisMergedThreshold.cfgRiskThresholdId =
                        configThreshold.riskThresholdId;
                    thisMergedThreshold.cfgRiskThresholdCode =
                        configThreshold.riskThresholdCode;
                    thisMergedThreshold.lowRisk =
                        configThreshold.lowRiskDefault;
                    thisMergedThreshold.mediumRisk =
                        configThreshold.mediumRiskDefault;
                    thisMergedThreshold.highRisk =
                        configThreshold.highRiskDefault;
                }
                mergedRiskThresholds.push(thisMergedThreshold);
            }
        );
        return mergedRiskThresholds;
    };

    getRiskThresholdSymbols = (
        riskThresholdCode: string
    ): RiskThresholdSymbols => {
        let fieldAdornment = '';
        let lowRiskSymbol = '';
        let mediumRiskSymbol = '';
        let highRiskSymbol = '';
        let position = 0;
        switch (riskThresholdCode.replace(/-|\s/g, '')) {
            case ThresholdEnum.PFRCONCRISK:
                fieldAdornment = '%';
                lowRiskSymbol = '<=';
                mediumRiskSymbol = '<';
                highRiskSymbol = '>=';
                position = 1;
                break;
            case ThresholdEnum.PROFITABILITYRISK:
                fieldAdornment = '%';
                lowRiskSymbol = '>';
                mediumRiskSymbol = '<=';
                highRiskSymbol = '<';
                position = 1;
                break;
            case ThresholdEnum.INPATREFSRCPROFITRISK:
            case ThresholdEnum.OUTPATREFSRCPROFITRISK:
                lowRiskSymbol = '>';
                mediumRiskSymbol = '<=';
                highRiskSymbol = '<';
                fieldAdornment = '$';
                position = 0;
                break;
            case ThresholdEnum.AMARISK:
                fieldAdornment = ' Days';
                lowRiskSymbol = '<=';
                mediumRiskSymbol = '<=';
                highRiskSymbol = '>';
                position = 1;
                break;
            default:
            // nothing
        }
        return {
            fieldAdornment: fieldAdornment,
            lowRiskSymbol: lowRiskSymbol,
            mediumRiskSymbol: mediumRiskSymbol,
            highRiskSymbol: highRiskSymbol,
            position: position,
        };
    };

    calculateMinimumDownPayment = (
        totalVariableCost: number,
        netPatientRevenue: number,
        remainingOopMax: number,
        totalPFR: number,
        variableCostCoveragePercent: number
    ) => {
        let minimumDownPayment = Math.max(
            totalVariableCost - netPatientRevenue,
            0
        );
        minimumDownPayment = Math.min(
            minimumDownPayment,
            remainingOopMax,
            totalPFR
        );
        return variableCostCoveragePercent < 100 ? minimumDownPayment : 0;
    };

    determineAdmitStatus = (
        estInsVal: number,
        netPatientRevenueEst: number,
        totalVariableCost: number,
        pfr: number
    ): AdmitStatus | null => {
        switch (true) {
            case estInsVal > totalVariableCost:
                return AdmitStatus.fast;

            case netPatientRevenueEst > totalVariableCost:
                return AdmitStatus.admit;

            case netPatientRevenueEst < totalVariableCost &&
                pfr + estInsVal >= totalVariableCost:
                return AdmitStatus.pending;

            case pfr + estInsVal < totalVariableCost:
                return AdmitStatus.noAdmit;

            default:
                return null;
        }
    };

    newDetermineAdmitStatus = (
        estInsVal: number,
        netPatientRevenueEst: number,
        totalVariableCost: number,
        pfr: number
    ): NewAdmitStatus => {
        switch (true) {
            case estInsVal > totalVariableCost:
                return NewAdmitStatus.FAST;

            case netPatientRevenueEst > totalVariableCost:
                return NewAdmitStatus.ADMIT;

            case netPatientRevenueEst < totalVariableCost &&
                pfr + estInsVal >= totalVariableCost:
                return NewAdmitStatus.PENDING;

            case pfr + estInsVal < totalVariableCost:
                return NewAdmitStatus.NOADMIT;

            default:
                return NewAdmitStatus.NOADMIT;
        }
    };

    calculateVariableCost = (
        monthlyOperatingCost: number,
        bedCapacity: number,
        monthlyVariableCostPercent: number,
        lengthOfStay: number,
        netPatientRevenue: number
    ) => {
        const totalMonthlyVariableCost =
            monthlyOperatingCost * (monthlyVariableCostPercent / 100);

        const monthlyVariableCostPerBedPerDay =
            totalMonthlyVariableCost / bedCapacity / 30;

        const totalVarialbeCost =
            monthlyVariableCostPerBedPerDay * lengthOfStay;

        const variableCostCoveragePercent =
            (netPatientRevenue / totalVarialbeCost) * 100;

        return {
            totalMonthlyVariableCost,
            monthlyVariableCostPerBedPerDay,
            variableCostCoveragePercent,
            totalVarialbeCost,
        };
    };

    makeMultiFacilityRiskAssessments = (
        facilities: ImplementationFacility[][],
        riskAssessmentFormState: RiskAssessmentForm,
        originalVob: Vob,
        originalEstimate: EstimatorBody,
        clientLevelsOfCare: EstLevelOfCare[],
        multiFacilityRiskThresholds: MultiFacilityRiskThreshold[],
        facilityRiskClassSettings: FacilityRiskClassSettings[],
        clientPayerClaimRates: PayerClaimRate[],
        clientRiskAssessmentConfigs: ClientRiskAssessmentFields[],
        clientDeficiencyNotificationSettings: ClientDeficiencySettings,
        multiFacilityReferralSourceRevenue: ReferralSourceRevenue[],
        hreOnly: boolean,
        vobClassifications: vobClassificationsItem[],
        editedFacilityCensus: EditedFacilityCensus[]
    ): FacilityRiskAssessment[] => {
        const originalEstimateFacilityId = originalEstimate.facility.facilityId;
        let multiFacilityRiskAssessments: FacilityRiskAssessment[] = [];

        const {facilityCensus} = riskAssessmentFormState;

        facilities.flat().forEach(facility => {
            let facilityRiskAssessmentFields:
                | ClientRiskAssessmentFields
                | undefined = clientRiskAssessmentConfigs.find(
                (riskConfig: ClientRiskAssessmentFields) => {
                    return riskConfig.clientFacilityId === facility.facilityId;
                }
            );

            if (!facilityRiskAssessmentFields) {
                facilityRiskAssessmentFields =
                    clientRiskAssessmentConfigs?.find(
                        (riskConfig: ClientRiskAssessmentFields) => {
                            return riskConfig.clientFacilityId === null;
                        }
                    )!;
            }

            const {
                variableCostPct,
                isFacilityCost,
                bedCapacityIp,
                bedCapacityOp,
                monthlyOperatingCostIp,
                monthlyOperatingCostOp,
            } = facilityRiskAssessmentFields;

            let facilityThresholds = multiFacilityRiskThresholds.find(
                facthresh => facthresh.clientFacilityId === facility.facilityId
            );
            let facilityRiskAssessment: FacilityRiskAssessment =
                Utils.deepClone(emptyFacilityRiskAssessment);

            facilityRiskAssessment.facilityId = facility.facilityId;
            facilityRiskAssessment.facilityName = facility.facilityName;

            facilityRiskAssessment.isHreOnly = hreOnly;
            facilityRiskAssessment.riskThresholds =
                facilityThresholds?.facilityRiskThresholds;

            if (facility.facilityId === originalEstimateFacilityId) {
                facilityRiskAssessment.estimateBody = originalEstimate;
                facilityRiskAssessment.isValid = true;
                facilityRiskAssessment.facilityLabel = 'Selected Facility';

                if (
                    originalEstimate.selectedPFRName ===
                    'Prior Care Adjusted PFR'
                ) {
                    const {adjustedCurrentPFR} =
                        admissionsAdvisorUtils.getPriorCareAdjChg(
                            originalEstimate,
                            originalVob
                        );
                    facilityRiskAssessment.pfrSummary = adjustedCurrentPFR;
                } else {
                    facilityRiskAssessment.pfrSummary =
                        admissionsAdvisorUtils.calculateFinancialSummary(
                            originalEstimate,
                            originalVob
                        );
                }
            } else {
                let facilityRiskAssessEstimate: RiskAssessmentEstimate =
                    this.makeFacilityEstimate(
                        facility,
                        originalVob,
                        originalEstimate,
                        clientLevelsOfCare
                    );

                facilityRiskAssessment.estimateBody =
                    facilityRiskAssessEstimate.estimateBody;
                facilityRiskAssessment.isValid =
                    facilityRiskAssessEstimate.isValid;

                if (facilityRiskAssessEstimate.isValid) {
                    if (
                        facilityRiskAssessEstimate.estimateBody
                            .selectedPFRName === 'Prior Care Adjusted PFR'
                    ) {
                        const {adjustedCurrentPFR} =
                            admissionsAdvisorUtils.getPriorCareAdjChg(
                                facilityRiskAssessEstimate.estimateBody,
                                originalVob
                            );
                        facilityRiskAssessment.pfrSummary = adjustedCurrentPFR;
                    } else {
                        facilityRiskAssessment.pfrSummary =
                            admissionsAdvisorUtils.calculateFinancialSummary(
                                facilityRiskAssessEstimate.estimateBody,
                                originalVob
                            );
                    }
                }
            }
            if (facilityRiskAssessment.isValid) {
                const {
                    estimateBody: {serviceLevel, selectedPFRName},
                } = facilityRiskAssessment;

                const isInpatient =
                    serviceLevel === 'inpatient' ||
                    serviceLevel === 'inpatientppp'
                        ? true
                        : false;
                const isPriorCare =
                    selectedPFRName === 'Prior Care Adjusted PFR';

                const totalLengthOfStay =
                    facilityRiskAssessment.pfrSummary.lengthOfStay;
                const coveredLengthOfStay =
                    facilityRiskAssessment.pfrSummary.lengthOfStayCovered ||
                    totalLengthOfStay;

                const pfrEivValues: PfrEiv = this.calcPfrEiv(
                    originalVob,
                    facilityRiskAssessment.estimateBody,
                    facilityRiskAssessment.pfrSummary
                );

                facilityRiskAssessment.daysToReimbursement =
                    this.calcReimbursementDays(
                        facilityRiskAssessment.estimateBody,
                        facilityRiskAssessment.pfrSummary.deductible,
                        facilityThresholds?.facilityRiskThresholds!
                    );
                facilityRiskAssessment.pfrRiskSettings = this.calcPfrRisk(
                    facilityRiskAssessment.pfrSummary,
                    facilityThresholds?.facilityRiskThresholds!
                );

                facilityRiskAssessment.facilityCensus = facilityCensus!;
                facilityRiskAssessment.estAdjChgs =
                    facilityRiskAssessment.pfrSummary.coveredCharges +
                    facilityRiskAssessment.pfrSummary.unCoveredCharges;
                facilityRiskAssessment.pfr = pfrEivValues.pfr;
                facilityRiskAssessment.estInsVal = pfrEivValues.eiv;

                if (pfrEivValues.estimate) {
                    facilityRiskAssessment.estimateBody = pfrEivValues.estimate;
                }

                // CW kluge
                // are we using form-level facilityCensus or facility-level (edited) facilityCensus?
                let thisEditedFacilityCensus = editedFacilityCensus?.find(
                    el => el.facilityId === facility.facilityId
                );
                let useCleared: boolean = false;
                if (riskAssessmentFormState.compareBest) {
                    // if we're comparing use cleared/hre state from form
                    useCleared = riskAssessmentFormState.showCleared;
                    // if a facility census has been set use it
                    if (thisEditedFacilityCensus) {
                        facilityRiskAssessment.facilityCensus =
                            thisEditedFacilityCensus.census;
                    }
                } else {
                    // otherwise use hreOnly parameter
                    useCleared = !hreOnly;
                }

                facilityRiskAssessment.netPatientRevenueEst =
                    this.calcNetPatientRevenueEstimate(
                        facility.facilityId,
                        originalVob.payer.payorId!,
                        originalVob.plan.payorPlanId!,
                        riskAssessmentFormState.riskClass?.riskClassId,
                        pfrEivValues.pfr,
                        pfrEivValues.eiv,
                        useCleared,
                        facilityRiskClassSettings,
                        clientPayerClaimRates
                    );

                let variableCostCalcResult;
                let estimatedAdjustedCost: number = 0;

                if (isInpatient) {
                    if (isFacilityCost) {
                        estimatedAdjustedCost =
                            this.calcOperatingCostsByFacility(
                                monthlyOperatingCostIp!,
                                bedCapacityIp!,
                                facilityCensus!,
                                variableCostPct!,
                                coveredLengthOfStay
                            );
                    } else {
                        estimatedAdjustedCost =
                            this.calcOperatingCostsByLevelOfCare(
                                facilityRiskAssessment.facilityCensus,
                                facilityRiskAssessment.estimateBody,
                                clientLevelsOfCare,
                                isInpatient,
                                variableCostPct
                            );
                    }

                    variableCostCalcResult = this.calculateVariableCost(
                        monthlyOperatingCostIp!,
                        bedCapacityIp!,
                        variableCostPct!,
                        totalLengthOfStay,
                        facilityRiskAssessment.netPatientRevenueEst
                    );
                } else {
                    if (isFacilityCost) {
                        estimatedAdjustedCost =
                            this.calcOperatingCostsByFacility(
                                monthlyOperatingCostOp!,
                                bedCapacityOp!,
                                facilityCensus!,
                                variableCostPct!,
                                coveredLengthOfStay
                            );
                    } else {
                        estimatedAdjustedCost =
                            this.calcOperatingCostsByLevelOfCare(
                                facilityRiskAssessment.facilityCensus,
                                facilityRiskAssessment.estimateBody,
                                clientLevelsOfCare,
                                isInpatient,
                                variableCostPct
                            );
                    }

                    variableCostCalcResult = this.calculateVariableCost(
                        monthlyOperatingCostOp!,
                        bedCapacityOp!,
                        variableCostPct!,
                        totalLengthOfStay,
                        facilityRiskAssessment.netPatientRevenueEst
                    );
                }

                let adjustedGrossMinusNPRestimate: number =
                    facilityRiskAssessment.estAdjChgs -
                    facilityRiskAssessment.netPatientRevenueEst;
                let calculatedAdmissionCost =
                    adjustedGrossMinusNPRestimate + estimatedAdjustedCost;
                let estimatedAdmissionProfit: number =
                    facilityRiskAssessment.estAdjChgs - calculatedAdmissionCost;

                // patient episode margin
                facilityRiskAssessment.patientEpisodeMargin =
                    (estimatedAdmissionProfit /
                        facilityRiskAssessment.estAdjChgs) *
                    100;
                facilityRiskAssessment.profitabilityRisk = this.calcProfitRisk(
                    facilityRiskAssessment.patientEpisodeMargin,
                    facilityThresholds?.facilityRiskThresholds!
                );

                facilityRiskAssessment.totalVariableCost =
                    variableCostCalcResult.totalVarialbeCost;
                facilityRiskAssessment.variableCostCoveragePercent =
                    variableCostCalcResult.variableCostCoveragePercent;

                const remainingOopMax = isPriorCare
                    ? facilityRiskAssessment.pfrSummary
                          .remainingOopMaxAfterServices
                    : facilityRiskAssessment.pfrSummary.remainingOopMaxFromVob;

                facilityRiskAssessment.minimumDownPayment =
                    this.calculateMinimumDownPayment(
                        facilityRiskAssessment.totalVariableCost,
                        facilityRiskAssessment.netPatientRevenueEst,
                        remainingOopMax,
                        facilityRiskAssessment.pfrSummary.totalPFR,
                        facilityRiskAssessment.variableCostCoveragePercent
                    );

                let minimumRevenueAmt: number = this.calcMinimumRevenueAmts(
                    facilityRiskAssessment.estimateBody,
                    clientLevelsOfCare,
                    originalVob.payer.inNetwork!
                );
                // eiv/pfr deficiencies

                facilityRiskAssessment.eivDeficiency =
                    minimumRevenueAmt > pfrEivValues.eiv
                        ? minimumRevenueAmt - pfrEivValues.eiv
                        : 0;
                facilityRiskAssessment.netPatientRevenueDeficiency =
                    minimumRevenueAmt >
                    facilityRiskAssessment.netPatientRevenueEst
                        ? minimumRevenueAmt -
                          facilityRiskAssessment.netPatientRevenueEst
                        : 0;
                facilityRiskAssessment.referralSourceRisk =
                    this.calcReferralSourceRevenueEstimate(
                        facility,
                        riskAssessmentFormState,
                        facilityRiskAssessment.estimateBody,
                        isInpatient,
                        multiFacilityReferralSourceRevenue,
                        facilityThresholds?.facilityRiskThresholds!
                    );
                facilityRiskAssessment.riskAssessment =
                    this.determineAdmitStatus(
                        facilityRiskAssessment.estInsVal,
                        facilityRiskAssessment.netPatientRevenueEst,
                        facilityRiskAssessment.totalVariableCost,
                        facilityRiskAssessment.pfr
                    );
            }

            multiFacilityRiskAssessments.push(facilityRiskAssessment);
        });

        return multiFacilityRiskAssessments;
    };

    calculateFacilityRiskAssessmentsWithNewEstimate = (
        facility: ImplementationFacility,
        riskAssessmentFormik: FormikProps<RiskAssessmentFormikValues>,
        originalVob: Vob,
        originalEstimate: Estimate,
        multiFacilityRiskThresholds: MultiFacilityRiskThreshold[],
        facilityRiskClassSettings: FacilityRiskClassSettings[],
        clientPayerClaimRates: PayerClaimRate[],
        clientRiskAssessmentConfigs: ClientRiskAssessmentFields[],
        multiFacilityReferralSourceRevenue: ReferralSourceRevenue[]
    ): NewFacilityRiskAssessment[] => {
        const facilityRiskAssessment: NewFacilityRiskAssessment =
            Utils.deepClone(newEmptyFacilityRiskAssessment);
        const facilityRiskAssessmentHRE: NewFacilityRiskAssessment =
            Utils.deepClone(newEmptyFacilityRiskAssessment);

        const {summary} = originalEstimate

        const {facilityCensus, riskClassId} = riskAssessmentFormik.values;

        let facilityRiskAssessmentFields:
            | ClientRiskAssessmentFields
            | undefined = clientRiskAssessmentConfigs.find(
            (riskConfig: ClientRiskAssessmentFields) => {
                return riskConfig.clientFacilityId === facility.facilityId;
            }
        );

        if (!facilityRiskAssessmentFields) {
            facilityRiskAssessmentFields = clientRiskAssessmentConfigs?.find(
                (riskConfig: ClientRiskAssessmentFields) => {
                    return riskConfig.clientFacilityId === null;
                }
            )!;
        }

        const {
            variableCostPct,
            isFacilityCost,
            bedCapacityIp,
            bedCapacityOp,
            monthlyOperatingCostIp,
            monthlyOperatingCostOp,
        } = facilityRiskAssessmentFields;

        let facilityThresholds = multiFacilityRiskThresholds.find(
            facthresh => facthresh.clientFacilityId === facility.facilityId
        );

        facilityRiskAssessment.facilityId = facility.facilityId;
        facilityRiskAssessmentHRE.facilityId = facility.facilityId;

        facilityRiskAssessment.facilityName = facility.facilityName;
        facilityRiskAssessmentHRE.facilityName = facility.facilityName;

        facilityRiskAssessment.isHreOnly = false;
        facilityRiskAssessmentHRE.isHreOnly = true;

        // @ts-ignore
        facilityRiskAssessment.riskThresholds =
            facilityThresholds?.facilityRiskThresholds;
        // @ts-ignore
        facilityRiskAssessmentHRE.riskThresholds =
            facilityThresholds?.facilityRiskThresholds;

        facilityRiskAssessment.facilityLabel = 'Selected Facility';
        facilityRiskAssessmentHRE.facilityLabel = 'HRE Selected Facility';

        const totalLengthOfStay = summary!.lengthOfStay;
        const coveredLengthOfStay = summary!.lengthOfStayCovered || totalLengthOfStay;

        const estAdjChgs = summary!.coveredCharges + summary!.unCoveredCharges; 

        facilityRiskAssessment.estAdjChgs = estAdjChgs 
        facilityRiskAssessmentHRE.estAdjChgs = estAdjChgs 


        const pfrEivValues: PfrEiv = this.newCalcPfrEiv(originalEstimate);

        facilityRiskAssessment.pfr = pfrEivValues.pfr;
        facilityRiskAssessmentHRE.pfr = pfrEivValues.pfr;

        facilityRiskAssessment.estInsVal = pfrEivValues.eiv;
        facilityRiskAssessmentHRE.estInsVal = pfrEivValues.eiv;

        const daysToReimbursement = this.newCalcReimbursementDays(
            originalEstimate,
            summary!.remainingDeductibleFromVob,
            facilityThresholds?.facilityRiskThresholds!
        );

        facilityRiskAssessment.daysToReimbursement = daysToReimbursement;
        facilityRiskAssessmentHRE.daysToReimbursement = daysToReimbursement;

        const pfrRiskSettings = this.newCalcPfrRisk(
            summary!,
            facilityThresholds?.facilityRiskThresholds!
        );

        facilityRiskAssessment.pfrRiskSettings = pfrRiskSettings;
        facilityRiskAssessmentHRE.pfrRiskSettings = pfrRiskSettings;

        facilityRiskAssessment.facilityCensus = facilityCensus!;
        facilityRiskAssessmentHRE.facilityCensus = facilityCensus!;

        const netPatientRevenueEst = this.calcNetPatientRevenueEstimate(
            facility.facilityId,
            originalVob.payer.payorId!,
            originalVob.plan.payorPlanId!,
            riskClassId,
            pfrEivValues.pfr,
            pfrEivValues.eiv,
            true,
            facilityRiskClassSettings,
            clientPayerClaimRates
        );

        const netPatientRevenueEstHRE = this.calcNetPatientRevenueEstimate(
            facility.facilityId,
            originalVob.payer.payorId!,
            originalVob.plan.payorPlanId!,
            riskClassId,
            pfrEivValues.pfr,
            pfrEivValues.eiv,
            false,
            facilityRiskClassSettings,
            clientPayerClaimRates
        );

        facilityRiskAssessment.netPatientRevenueEst = netPatientRevenueEst;
        facilityRiskAssessmentHRE.netPatientRevenueEst =
            netPatientRevenueEstHRE;

        // TODO: similar to comment below
        // TODO: figure out a more saphisticated way of determining isInPatient,
        // given the different loc in different vob
        // bedCapacityIp or bedCapacityOp
        let variableCostCalcResult = this.calculateVariableCost(
            monthlyOperatingCostIp!,
            bedCapacityIp!,
            variableCostPct!,
            totalLengthOfStay,
            facilityRiskAssessment.netPatientRevenueEst
        );

		let variableCostCalcResultHre = this.calculateVariableCost(
            monthlyOperatingCostIp!,
            bedCapacityIp!,
            variableCostPct!,
            totalLengthOfStay,
            facilityRiskAssessmentHRE.netPatientRevenueEst
        );

        let estimatedAdjustedCost: number = 0;

        if (isFacilityCost) {
            estimatedAdjustedCost = this.calcOperatingCostsByFacility(
                monthlyOperatingCostIp!,
                bedCapacityIp!,
                facilityCensus!,
                variableCostPct!,
                coveredLengthOfStay
            );
        } else {
            estimatedAdjustedCost = this.newCalcOperatingCostsByLevelOfCare(
                facilityRiskAssessment.facilityCensus,
                originalEstimate,
                variableCostPct
            );
        }

        let adjustedGrossMinusNPRestimate: number =
            facilityRiskAssessment.estAdjChgs -
            facilityRiskAssessment.netPatientRevenueEst;
        let calculatedAdmissionCost =
            adjustedGrossMinusNPRestimate + estimatedAdjustedCost;
        let estimatedAdmissionProfit: number =
            facilityRiskAssessment.estAdjChgs - calculatedAdmissionCost;

        // patient episode margin
        const patientEpisodeMargin =
            (estimatedAdmissionProfit / facilityRiskAssessment.estAdjChgs) *
            100;

        facilityRiskAssessment.patientEpisodeMargin = patientEpisodeMargin;
        facilityRiskAssessmentHRE.patientEpisodeMargin = patientEpisodeMargin;

        const profitabilityRisk = this.newCalcProfitRisk(
            facilityRiskAssessment.patientEpisodeMargin,
            facilityThresholds?.facilityRiskThresholds!
        );
        facilityRiskAssessment.profitabilityRisk = profitabilityRisk;
        facilityRiskAssessmentHRE.profitabilityRisk = profitabilityRisk;

        facilityRiskAssessment.totalVariableCost =
            variableCostCalcResult.totalVarialbeCost;
        facilityRiskAssessmentHRE.totalVariableCost =
            variableCostCalcResultHre.totalVarialbeCost;

        facilityRiskAssessment.variableCostCoveragePercent =
            variableCostCalcResult.variableCostCoveragePercent;
        facilityRiskAssessmentHRE.variableCostCoveragePercent =
            variableCostCalcResultHre.variableCostCoveragePercent;

        const minimumDownPayment = this.calculateMinimumDownPayment(
            facilityRiskAssessment.totalVariableCost,
            facilityRiskAssessment.netPatientRevenueEst,
            summary!.remainingOopMaxAfterServices,
            summary!.totalPFR,
            facilityRiskAssessment.variableCostCoveragePercent
        );

		const minimumDownPaymentHre = this.calculateMinimumDownPayment(
            facilityRiskAssessment.totalVariableCost,
            facilityRiskAssessment.netPatientRevenueEst,
            summary!.remainingOopMaxAfterServices,
            summary!.totalPFR,
            facilityRiskAssessmentHRE.variableCostCoveragePercent
        );

        facilityRiskAssessment.minimumDownPayment = minimumDownPayment;
        facilityRiskAssessmentHRE.minimumDownPayment = minimumDownPaymentHre;

        const minimumRevenueAmt: number =
            this.newCalcMinimumRevenueAmts(originalEstimate);
        // eiv/pfr deficiencies

        const eivDeficiency =
            minimumRevenueAmt > pfrEivValues.eiv
                ? minimumRevenueAmt - pfrEivValues.eiv
                : 0;

        facilityRiskAssessment.eivDeficiency = eivDeficiency;
        facilityRiskAssessmentHRE.eivDeficiency = eivDeficiency;

        const netPatientRevenueDeficiency =
            minimumRevenueAmt > facilityRiskAssessment.netPatientRevenueEst
                ? minimumRevenueAmt -
                  facilityRiskAssessment.netPatientRevenueEst
                : 0;

        facilityRiskAssessment.netPatientRevenueDeficiency =
            netPatientRevenueDeficiency;
        facilityRiskAssessmentHRE.netPatientRevenueDeficiency =
            netPatientRevenueDeficiency;

        const referralSourceRisk = this.newCalcReferralSourceRevenueEstimate(
            facility,
            riskAssessmentFormik,
            originalEstimate,
            multiFacilityReferralSourceRevenue,
            facilityThresholds?.facilityRiskThresholds!
        );
        facilityRiskAssessment.referralSourceRisk = referralSourceRisk;
        facilityRiskAssessmentHRE.referralSourceRisk = referralSourceRisk;

        const admitStatus = this.newDetermineAdmitStatus(
            facilityRiskAssessment.estInsVal,
            facilityRiskAssessment.netPatientRevenueEst,
            facilityRiskAssessment.totalVariableCost,
            facilityRiskAssessment.pfr
        );

        facilityRiskAssessment.admitStatus = admitStatus;
        facilityRiskAssessmentHRE.admitStatus = admitStatus;

        return [facilityRiskAssessment, facilityRiskAssessmentHRE];
    };

    makeFacilityEstimate = (
        facility: ImplementationFacility,
        originalVob: Vob,
        originalEstimate: EstimatorBody,
        clientLevelsOfCare: EstLevelOfCare[]
    ): RiskAssessmentEstimate => {
        let facilityEstimateCopy: EstimatorBody =
            Utils.deepClone(originalEstimate);
        // it's possible we can't make a valid estimate using this Facility
        let isValid: boolean = true;
        // this array will hold the equivalent selectedLevelsOfCare for this facilityId
        let matchingLevelsOfCare: EditEstLevelOfCare[] = [];
        facilityEstimateCopy.facility.facilityId = facility.facilityId;
        facilityEstimateCopy.facility.facilityName = facility.facilityName;
        // if selectedLevelsOfCareFacilityType = 'multi' we just keep levels of care
        // otherwise we have to find matching ones for this facility
        if (
            facilityEstimateCopy.selectedLevelsOfCareFacilityType === 'selected'
        ) {
            // find levels of care in this facility that match those in originalEstimate
            facilityEstimateCopy.selectedLevelsOfCare?.forEach(loc => {
                let matchingFacilityLevelofCare = clientLevelsOfCare.find(
                    item =>
                        item.clientFacilityId === facility.facilityId &&
                        item.cfgLevelOfCareId === loc.cfgLevelOfCareId
                );
                if (matchingFacilityLevelofCare) {
                    let locCopy: EditEstLevelOfCare = Utils.deepClone(
                        matchingFacilityLevelofCare
                    );
                    // is this level of care in network?
                    let locInNetwork: boolean = false;
                    if (
                        locCopy.facilityPayorLocRateLos?.cfgPayorPlanId ===
                        originalVob.payer.payorId
                    ) {
                        locInNetwork = true;
                    }
                    // facility-specific level of care rates and length of stay
                    let ratesLos: RatesLos = getRateAndLosFromQuoteMethod(
                        locCopy,
                        originalEstimate.quoteMethod!,
                        locInNetwork
                    );
                    // manually entered rate and los must be brought over from originalEstimate
                    switch (facilityEstimateCopy.quoteMethod) {
                        case 'sca':
                            locCopy.scaRatesLos = {
                                pdrRate: loc.scaRatesLos?.pdrRate,
                                losDays: loc.scaRatesLos?.losDays,
                            };
                            locCopy.selectedRateLos = {
                                pdrRate: loc.scaRatesLos?.pdrRate,
                                losDays: loc.scaRatesLos?.losDays,
                            };
                            break;
                        case 'manual':
                            locCopy.manualRatesLos = {
                                pdrRate: loc.manualRatesLos?.losDays,
                                losDays: ratesLos.losDays,
                            };
                            locCopy.selectedRateLos = {
                                pdrRate: loc.manualRatesLos?.losDays,
                                losDays: ratesLos.losDays,
                            };
                            break;
                        default:
                            locCopy.manualRatesLos = {
                                pdrRate: ratesLos.pdrRate,
                                losDays: 0,
                            };
                            locCopy.scaRatesLos = {
                                pdrRate: 0,
                                losDays: 0,
                            };
                            locCopy.selectedRateLos = {
                                pdrRate: ratesLos.pdrRate,
                                losDays: ratesLos.losDays,
                            };
                    }
                    if (originalVob.selfPay) {
                        if (locCopy?.facilityPayorLocRateLos?.isCovered) {
                            locCopy.facilityPayorLocRateLos.isCovered = false;
                        }
                    }
                    matchingLevelsOfCare.push(locCopy);
                } else {
                    isValid = false;
                }
            });

            // add selectedLevelsOfCare for this facility to facilityEstimateCopy
            if (isValid && matchingLevelsOfCare.length > 0) {
                facilityEstimateCopy.selectedLevelsOfCare =
                    matchingLevelsOfCare;
            }
        }

        return {
            isValid: isValid,
            estimateBody: facilityEstimateCopy,
        };
    };

    newCalcReimbursementDays = (
        estimateBody: Estimate,
        deductible: number,
        riskThresholds: FacilityRiskThreshold[]
    ): NewValueRisk => {
        let reimburseData: NewValueRisk = {
            value: 0,
            risk: NewRiskStatus.NONE,
        };
        let daysTilDeductibleSpent: number;
        let riskStatus: NewRiskStatus = NewRiskStatus.NONE;

        // get first level of care (try sortOrder but might not be set)
        let firstLevelOfCare: FacilityLevelOfCare | null | undefined = null;
        let sortableLoc = estimateBody.facilityLevelOfCare?.filter(
            loc => loc.sortOrder
        );
        if (sortableLoc) {
            sortableLoc.sort((a, b) => {
                return a.sortOrder! - b.sortOrder!;
            });
            firstLevelOfCare = sortableLoc.length ? sortableLoc[0] : null;
        } else {
            // no sort order has been applied.  Look for a level of care with "detox" in the name
            const detoxLocs = estimateBody.facilityLevelOfCare?.filter(loc => {
                return (
                    loc.facilityLevelOfCareName
                        ?.toString()
                        .toLowerCase()
                        .includes('detox') ||
                    loc?.facilityLevelOfCareCode
                        ?.toString()
                        .toLowerCase()
                        .includes('detox')
                );
            });
            if (detoxLocs) {
                firstLevelOfCare = detoxLocs.length ? detoxLocs[0] : null;
            }
        }
        if (firstLevelOfCare) {
            daysTilDeductibleSpent = deductible / firstLevelOfCare.pdrRate!;
        } else {
            // can't determine firstLevelOfCare from sort order or "detox", so just use selectedLevelsOfCare[0]
            firstLevelOfCare = estimateBody.facilityLevelOfCare![0];
            daysTilDeductibleSpent = deductible / firstLevelOfCare.pdrRate!;
        }

        // get AMARISK threshold
        let amaRiskThresholds = riskThresholds?.find(
            thresh => thresh.cfgRiskThresholdCode === ThresholdEnum.AMARISK
        );
        if (amaRiskThresholds) {
            switch (true) {
                case daysTilDeductibleSpent <= amaRiskThresholds.lowRisk!:
                    riskStatus = NewRiskStatus.LOW;
                    break;
                case daysTilDeductibleSpent > amaRiskThresholds.lowRisk! &&
                    daysTilDeductibleSpent < amaRiskThresholds.highRisk!:
                    riskStatus = NewRiskStatus.MEDIUM;
                    break;
                case daysTilDeductibleSpent >= amaRiskThresholds.highRisk!:
                    riskStatus = NewRiskStatus.HIGH;
                    break;
            }
        }

        reimburseData.value = daysTilDeductibleSpent;
        reimburseData.risk = riskStatus;
        return reimburseData;
    };

    calcReimbursementDays = (
        estimateBody: EstimatorBody,
        deductible: number,
        riskThresholds: FacilityRiskThreshold[]
    ): ValueRisk => {
        let reimburseData: ValueRisk = {
            value: 0,
            risk: RiskStatus.none,
        };
        let daysTilDeductibleSpent: number;
        let riskStatus: RiskStatus = RiskStatus.none;

        // get first level of care (try sortOrder but might not be set)
        let firstLevelOfCare: EditEstLevelOfCare | null | undefined = null;
        let sortableLoc = estimateBody.selectedLevelsOfCare?.filter(
            loc => loc.sortOrder
        );
        if (sortableLoc) {
            sortableLoc.sort((a, b) => {
                return a.sortOrder! - b.sortOrder!;
            });
            firstLevelOfCare = sortableLoc.length ? sortableLoc[0] : null;
        } else {
            // no sort order has been applied.  Look for a level of care with "detox" in the name
            const detoxLocs = estimateBody.selectedLevelsOfCare?.filter(loc => {
                return (
                    loc.facilityLevelOfCareName
                        ?.toString()
                        .toLowerCase()
                        .includes('detox') ||
                    loc?.facilityLevelOfCareCode
                        ?.toString()
                        .toLowerCase()
                        .includes('detox')
                );
            });
            if (detoxLocs) {
                firstLevelOfCare = detoxLocs.length ? detoxLocs[0] : null;
            }
        }
        if (firstLevelOfCare) {
            daysTilDeductibleSpent =
                deductible / firstLevelOfCare.selectedRateLos?.pdrRate!;
        } else {
            // can't determine firstLevelOfCare from sort order or "detox", so just use selectedLevelsOfCare[0]
            firstLevelOfCare = estimateBody.selectedLevelsOfCare![0];
            daysTilDeductibleSpent =
                deductible / firstLevelOfCare.selectedRateLos?.pdrRate!;
        }

        // get AMARISK threshold
        let amaRiskThresholds = riskThresholds?.find(
            thresh => thresh.cfgRiskThresholdCode === ThresholdEnum.AMARISK
        );
        if (amaRiskThresholds) {
            switch (true) {
                case daysTilDeductibleSpent <= amaRiskThresholds.lowRisk!:
                    riskStatus = RiskStatus.low;
                    break;
                case daysTilDeductibleSpent > amaRiskThresholds.lowRisk! &&
                    daysTilDeductibleSpent < amaRiskThresholds.highRisk!:
                    riskStatus = RiskStatus.medium;
                    break;
                case daysTilDeductibleSpent >= amaRiskThresholds.highRisk!:
                    riskStatus = RiskStatus.high;
                    break;
            }
        }

        reimburseData.value = daysTilDeductibleSpent;
        reimburseData.risk = riskStatus;
        return reimburseData;
    };

    newCalcPfrRisk = (
        pfrSummary: EstimateSummary,
        riskThresholds: FacilityRiskThreshold[]
    ): NewValueRisk => {
        let pfrRiskData: NewValueRisk = {
            value: 0,
            risk: NewRiskStatus.NONE,
        };
        let totalChgs: number =
            pfrSummary.coveredCharges + pfrSummary.unCoveredCharges;
        let pfrPct = (pfrSummary.totalPFR / totalChgs) * 100;
        let pfrRisk = NewRiskStatus.NONE;
        // get PFRCONCRISK threshold
        let pfrConcRiskThresholds = riskThresholds?.find(
            thresh => thresh.cfgRiskThresholdCode === ThresholdEnum.PFRCONCRISK
        );
        if (pfrConcRiskThresholds) {
            switch (true) {
                case pfrPct <= pfrConcRiskThresholds.lowRisk!:
                    pfrRisk = NewRiskStatus.LOW;
                    break;
                case pfrPct > pfrConcRiskThresholds.lowRisk! &&
                    pfrPct < pfrConcRiskThresholds.mediumRisk!:
                    pfrRisk = NewRiskStatus.MEDIUM;
                    break;
                case pfrPct >= pfrConcRiskThresholds.mediumRisk!:
                    pfrRisk = NewRiskStatus.HIGH;
                    break;
            }
        }
        pfrRiskData.value = pfrPct;
        pfrRiskData.risk = pfrRisk;
        return pfrRiskData;
    };

    calcPfrRisk = (
        pfrSummary: PFRSummary,
        riskThresholds: FacilityRiskThreshold[]
    ): ValueRisk => {
        let pfrRiskData: ValueRisk = {
            value: 0,
            risk: RiskStatus.none,
        };
        let totalChgs: number =
            pfrSummary.coveredCharges + pfrSummary.unCoveredCharges;
        let pfrPct = (pfrSummary.totalPFR / totalChgs) * 100;
        let pfrRisk = RiskStatus.none;
        // get PFRCONCRISK threshold
        let pfrConcRiskThresholds = riskThresholds?.find(
            thresh => thresh.cfgRiskThresholdCode === ThresholdEnum.PFRCONCRISK
        );
        if (pfrConcRiskThresholds) {
            switch (true) {
                case pfrPct <= pfrConcRiskThresholds.lowRisk!:
                    pfrRisk = RiskStatus.low;
                    break;
                case pfrPct > pfrConcRiskThresholds.lowRisk! &&
                    pfrPct < pfrConcRiskThresholds.mediumRisk!:
                    pfrRisk = RiskStatus.medium;
                    break;
                case pfrPct >= pfrConcRiskThresholds.mediumRisk!:
                    pfrRisk = RiskStatus.high;
                    break;
            }
        }
        pfrRiskData.value = pfrPct;
        pfrRiskData.risk = pfrRisk;
        return pfrRiskData;
    };

    newCalcPfrEiv = (estimate: Estimate): PfrEiv => {
        const eiv = Math.max(
            estimate.summary!.coveredCharges - estimate.summary!.netPFR,
            0
        ) ?? 0.0;

        const thisPfrEiv: PfrEiv = {
            pfr: estimate.summary!.totalPFR ?? 0.0,
            eiv
        };

        return thisPfrEiv;
    };

    calcPfrEiv = (
        originalVob: Vob,
        facilityEstimate: EstimatorBody,
        pfrSummary: PFRSummary
    ): PfrEiv => {
        let pfrAmt: number = 0.0;
        let netPfrAmt: number = 0.0;
        let eivAmt: number = 0.0;
        let facilityEstimateCopy = Utils.deepClone(facilityEstimate);
        let thisPfrEiv: PfrEiv = {
            pfr: 0.0,
            eiv: 0.0,
        };
        switch (facilityEstimate.selectedPFRName) {
            case 'Prior Care Adjusted PFR':
                const priorCareAdjustment: PriorCarePFR =
                    admissionsAdvisorUtils.getPriorCareAdjChg(
                        facilityEstimate,
                        originalVob
                    );
                pfrAmt = priorCareAdjustment.adjustedCurrentPFR.totalPFR;
                eivAmt =
                    priorCareAdjustment.adjustedCurrentPFR.coveredCharges -
                    priorCareAdjustment.adjustedCurrentPFR.netPFR;
                facilityEstimateCopy.priorCareAdjustmentPfr = pfrAmt;
                break;
            case 'Plan Crossover':
                const planCrossoverPolicies: CrossoverPolicies =
                    admissionsAdvisorUtils.calculateCrossoverPfr(
                        facilityEstimate?.planYearCrossover?.crossoverDays!,
                        facilityEstimate,
                        originalVob
                    );
                if (
                    facilityEstimate.planYearCrossover
                        ?.usingPlanYearCrossoverPfr &&
                    facilityEstimate.planYearCrossover
                        ?.planYearCrossoverConfirmed
                ) {
                    pfrAmt =
                        planCrossoverPolicies.currentPolicyFinancialSummary
                            ?.totalPFR! +
                        planCrossoverPolicies.nextPolicyFinancialSummary
                            ?.totalPFR!;
                    netPfrAmt =
                        planCrossoverPolicies.currentPolicyFinancialSummary
                            ?.netPFR! +
                        planCrossoverPolicies.nextPolicyFinancialSummary
                            ?.netPFR!;
                    eivAmt =
                        planCrossoverPolicies.currentPolicyFinancialSummary
                            ?.coveredCharges! +
                        planCrossoverPolicies.nextPolicyFinancialSummary
                            ?.coveredCharges! -
                        netPfrAmt;
                    facilityEstimateCopy.crossoverAdjustmentPfr = pfrAmt;
                } else {
                    pfrAmt = pfrSummary.totalPFR;
                    eivAmt = pfrSummary.coveredCharges - pfrSummary.netPFR;
                    facilityEstimateCopy.crossoverAdjustmentPfr = pfrAmt;
                }
                break;
            case 'Financial Assistance PFR':
                let patientPfr: number = 0.0;
                let crossoverPolicies: CrossoverPolicies = {
                    currentPolicyFinancialSummary: emptyPFRSummary,
                    nextPolicyFinancialSummary: emptyPFRSummary,
                };
                if (
                    facilityEstimate.planYearCrossover
                        ?.usingPlanYearCrossoverPfr &&
                    facilityEstimate.planYearCrossover
                        ?.planYearCrossoverConfirmed
                ) {
                    crossoverPolicies =
                        admissionsAdvisorUtils.calculateCrossoverPfr(
                            facilityEstimate.planYearCrossover.crossoverDays!,
                            facilityEstimate,
                            originalVob
                        );
                }
                if (
                    facilityEstimate.planYearCrossover
                        ?.usingPlanYearCrossoverPfr &&
                    facilityEstimate.planYearCrossover
                        ?.planYearCrossoverConfirmed
                ) {
                    patientPfr =
                        crossoverPolicies?.nextPolicyFinancialSummary
                            ?.totalPFR! +
                        crossoverPolicies?.currentPolicyFinancialSummary
                            ?.totalPFR!;
                } else {
                    patientPfr = pfrSummary.totalPFR;
                }
                let scholarshipPct = 1;
                if (
                    facilityEstimate.financialAssistance?.qualifiesForAssistance
                ) {
                    if (
                        facilityEstimate?.financialAssistance
                            ?.scholarshipPercentage
                    ) {
                        scholarshipPct =
                            facilityEstimate?.financialAssistance
                                ?.scholarshipPercentage;
                    }
                    pfrAmt = patientPfr * scholarshipPct;
                    eivAmt =
                        pfrSummary.coveredCharges * scholarshipPct - pfrAmt;
                } else {
                    pfrAmt = pfrSummary.totalPFR;
                    eivAmt = pfrSummary.coveredCharges - pfrSummary.netPFR;
                }
                facilityEstimateCopy.financialAssistanceAdjustmentPfr = pfrAmt;
                break;
            default:
                pfrAmt = pfrSummary.totalPFR;
                eivAmt = pfrSummary.coveredCharges - pfrSummary.netPFR;
                facilityEstimateCopy.totalEstimatedPfr = pfrAmt;
        }
        thisPfrEiv.pfr = pfrAmt;
        thisPfrEiv.eiv = eivAmt >= 0 ? eivAmt : 0;
        thisPfrEiv.estimate = facilityEstimateCopy;
        return thisPfrEiv;
    };

    calcNetPatientRevenueEstimate = (
        facilityId: number,
        payerId: number,
        planId: number,
        selectedRiskClassId: number | undefined,
        pfr: number,
        eiv: number,
        isCleared: boolean,
        facilityRiskClassSettings: FacilityRiskClassSettings[],
        clientPayerClaimRates: PayerClaimRate[]
    ): number => {
        // first get claim rate
        let claimRate: number = 0;

        let claim = clientPayerClaimRates?.find(claimRate => {
            return (
                (claimRate.cfgPayorId === payerId &&
                    claimRate.cfgPayorPlanId === null) ||
                (claimRate.cfgPayorId === null &&
                    claimRate.cfgPayorPlanId === planId)
            );
        });

        if (claim) {
            claimRate = claim.claimRatePct || 0;
        }
        // second get facilityRiskClassSettings
        let clearedValue: number = 0;
        let hreValue: number = 0;

        let selectedFacilityRiskClass = facilityRiskClassSettings.find(
            facrisk =>
                facrisk.clientFacilityId === facilityId &&
                facrisk.riskClassId === selectedRiskClassId
        );

        if (selectedFacilityRiskClass) {
            clearedValue = selectedFacilityRiskClass.riskClassCleared;
            hreValue = selectedFacilityRiskClass.riskClassHre;
        }
        // calculate
        let payerPays: number = claimRate > 0 ? eiv * (claimRate / 100) : 0;

        let patientPays: number = 0;

        if (isCleared) {
            patientPays = clearedValue > 0 ? pfr * (clearedValue / 100) : 0;
        } else {
            patientPays = hreValue > 0 ? pfr * (hreValue / 100) : 0;
        }

        return payerPays + patientPays;
    };

    newCalcOperatingCostsByLevelOfCare = (
        facilityCensus: number,
        estimateBody: Estimate,
        clientVariableCostPct: number | null | undefined
    ): number => {
        // Per diem Operating Cost method
        let facilityEstimateCopy: Estimate = Utils.deepClone(estimateBody);
        let totalLocCosts: number = 0;
        let censusAdjustedCostPerBed: number = 0;

        facilityEstimateCopy.facilityLevelOfCare?.forEach(loc => {
            censusAdjustedCostPerBed = 0;
            if (loc.perDiemOperatingCost) {
                censusAdjustedCostPerBed =
                    loc.perDiemOperatingCost / (facilityCensus / 100);
            }
            if (clientVariableCostPct) {
                censusAdjustedCostPerBed =
                    censusAdjustedCostPerBed * (clientVariableCostPct / 100);
            }
            totalLocCosts += censusAdjustedCostPerBed * loc.losDays!;
        });

        return totalLocCosts;
    };

    calcOperatingCostsByLevelOfCare = (
        facilityCensus: number,
        estimateBody: EstimatorBody,
        clientLevelsOfCare: EstLevelOfCare[],
        isInpatient: boolean,
        clientVariableCostPct: number | null | undefined
    ): number => {
        // Per diem Operating Cost method

        let facilityEstimateCopy: EstimatorBody = Utils.deepClone(estimateBody);
        let totalLocCosts: number = 0;
        let censusAdjustedCostPerBed: number = 0;

        facilityEstimateCopy.selectedLevelsOfCare?.forEach(loc => {
            censusAdjustedCostPerBed = 0;
            const selectedLoc = clientLevelsOfCare.find(
                clLoc =>
                    clLoc.facilityLevelOfCareId === loc.facilityLevelOfCareId
            );
            if (selectedLoc) {
                let ratesLos: RatesLos = getRateAndLosFromQuoteMethod(
                    loc,
                    estimateBody.quoteMethod!,
                    isInpatient
                );
                if (selectedLoc.perDiemOperatingCost) {
                    censusAdjustedCostPerBed =
                        selectedLoc.perDiemOperatingCost /
                        (facilityCensus / 100);
                }
                if (clientVariableCostPct) {
                    censusAdjustedCostPerBed =
                        censusAdjustedCostPerBed *
                        (clientVariableCostPct / 100);
                }
                totalLocCosts += censusAdjustedCostPerBed * ratesLos.losDays!;
            }
        });

        return totalLocCosts;
    };

    calcOperatingCostsByFacility = (
        monthlyOperatingCost: number,
        bedCapacity: number,
        facilityCensus: number,
        clientVariableCostPct: number,
        lengthOfStay: number
    ): number => {
        const totalVariableCost =
            monthlyOperatingCost * (clientVariableCostPct / 100);
        const totalFixedCosts = monthlyOperatingCost - totalVariableCost;

        const variableCostPerBedPerDay = totalVariableCost / bedCapacity / 30;
        const fixedCostPerBedPerDay = totalFixedCosts / bedCapacity / 30;

        const censusAdjustedFixedCostPerBedPerDay =
            fixedCostPerBedPerDay / (facilityCensus / 100);

        const variableCostForAdmission =
            variableCostPerBedPerDay * lengthOfStay;
        const fixedCostForAdmission =
            censusAdjustedFixedCostPerBedPerDay * lengthOfStay;

        const totalAdmissionCost =
            variableCostForAdmission + fixedCostForAdmission;
        return totalAdmissionCost;
    };

    newCalcMinimumRevenueAmts(estimateBody: Estimate): number {
        let minRevenueAmt: number = 0;

        estimateBody.facilityLevelOfCare?.forEach(loc => {
            let isFlatRate: boolean = loc.minRevenueFlatRate
                ? loc.minRevenueFlatRate
                : false;

            if (isFlatRate) {
                if (loc.minRevenueAmount) {
                    minRevenueAmt += !isNaN(loc.minRevenueAmount)
                        ? loc.minRevenueAmount
                        : 0;
                }
            } else {
                if (loc.minRevenueAmount) {
                    if (!isNaN(loc.minRevenueAmount) && !isNaN(loc.losDays!)) {
                        minRevenueAmt += loc.minRevenueAmount * loc.losDays!;
                    }
                }
            }
        });

        return minRevenueAmt;
    }

    calcMinimumRevenueAmts(
        estimateBody: EstimatorBody,
        clientLevelsOfCare: EstLevelOfCare[],
        inNetwork: boolean
    ): number {
        let minRevenueAmt: number = 0;
        estimateBody.selectedLevelsOfCare?.forEach(loc => {
            const selectedLoc = clientLevelsOfCare.find(
                clLoc =>
                    clLoc.facilityLevelOfCareId === loc.facilityLevelOfCareId
            );
            if (selectedLoc) {
                let ratesLos: RatesLos = getRateAndLosFromQuoteMethod(
                    loc,
                    estimateBody.quoteMethod!,
                    inNetwork
                );

                let isFlatRate: boolean = selectedLoc.minRevenueFlatRate
                    ? selectedLoc.minRevenueFlatRate
                    : false;
                if (isFlatRate) {
                    if (selectedLoc.minRevenueAmount) {
                        minRevenueAmt += !isNaN(selectedLoc.minRevenueAmount)
                            ? selectedLoc.minRevenueAmount
                            : 0;
                    }
                } else {
                    if (selectedLoc.minRevenueAmount) {
                        if (
                            !isNaN(selectedLoc.minRevenueAmount) &&
                            !isNaN(ratesLos.losDays!)
                        ) {
                            minRevenueAmt +=
                                selectedLoc.minRevenueAmount *
                                ratesLos.losDays!;
                        }
                    }
                }
            }
        });

        return minRevenueAmt;
    }

    calcProfitRisk = (
        patientEpisodeMargin: number,
        riskThresholds: FacilityRiskThreshold[]
    ): ValueRisk => {
        let profitRiskData: ValueRisk = {
            value: 0,
            risk: RiskStatus.none,
        };
        let profitRisk = RiskStatus.none;
        // get PROFITABILITYRISK threshold
        let pfrConcRiskThresholds = riskThresholds?.find(
            thresh =>
                thresh.cfgRiskThresholdCode === ThresholdEnum.PROFITABILITYRISK
        );
        if (pfrConcRiskThresholds) {
            switch (true) {
                case patientEpisodeMargin >= pfrConcRiskThresholds.lowRisk!:
                    profitRisk = RiskStatus.low;
                    break;
                case patientEpisodeMargin > pfrConcRiskThresholds.highRisk!:
                    profitRisk = RiskStatus.medium;
                    break;
                default:
                    profitRisk = RiskStatus.high;
                    break;
            }
        }
        profitRiskData.value = patientEpisodeMargin;
        profitRiskData.risk = profitRisk;
        return profitRiskData;
    };

    newCalcProfitRisk = (
        patientEpisodeMargin: number,
        riskThresholds: FacilityRiskThreshold[]
    ): NewValueRisk => {
        let profitRiskData: NewValueRisk = {
            value: 0,
            risk: NewRiskStatus.NONE,
        };
        let profitRisk = NewRiskStatus.NONE;
        // get PROFITABILITYRISK threshold
        let pfrConcRiskThresholds = riskThresholds?.find(
            thresh =>
                thresh.cfgRiskThresholdCode === ThresholdEnum.PROFITABILITYRISK
        );
        if (pfrConcRiskThresholds) {
            switch (true) {
                case patientEpisodeMargin >= pfrConcRiskThresholds.lowRisk!:
                    profitRisk = NewRiskStatus.LOW;
                    break;
                case patientEpisodeMargin > pfrConcRiskThresholds.highRisk!:
                    profitRisk = NewRiskStatus.MEDIUM;
                    break;
                default:
                    profitRisk = NewRiskStatus.HIGH;
                    break;
            }
        }
        profitRiskData.value = patientEpisodeMargin;
        profitRiskData.risk = profitRisk;
        return profitRiskData;
    };

    newCalcReferralSourceRevenueEstimate = (
        facility: ImplementationFacility,
        riskAssessmentForm: FormikProps<RiskAssessmentFormikValues>,
        estimateBody: Estimate,
        multiFacilityReferralSourceRevenue: ReferralSourceRevenue[],
        riskThresholds: FacilityRiskThreshold[]
    ): NewValueRisk => {
        // Revenue Estimate from referral source data = sum of quoted LOS for each LOC * rate from "referral source revenue data" table for each LOC

        let referralRevenueData: NewValueRisk = {
            value: 0,
            risk: NewRiskStatus.NONE,
        };

        let facilityHasReferralSourceRevenue: boolean = false;

        // has a referral source been selected?
        if (riskAssessmentForm.values.facilityReferralSourceId) {
            const selectedReferralSourceId: number =
                riskAssessmentForm.values.facilityReferralSourceId;
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            let facilityReferralSource = <ReferralSourceRevenue | undefined>{};
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            let selectedFacilityReferralSource = <
                ReferralSourceRevenue | undefined
            >{};

            estimateBody.facilityLevelOfCare?.forEach(loc => {
                facilityReferralSource =
                    multiFacilityReferralSourceRevenue.find(
                        refsrc =>
                            refsrc.facilityReferralSourceId ===
                                selectedReferralSourceId &&
                            refsrc.clientFacilityId === facility.facilityId &&
                            refsrc.facilityLevelOfCareId ===
                                loc.facilityLevelOfCareId
                    );

                selectedFacilityReferralSource =
                    multiFacilityReferralSourceRevenue.find(
                        refsrc =>
                            refsrc.facilityReferralSourceId ===
                            selectedReferralSourceId
                    );

                if (selectedFacilityReferralSource?.clientReferralSourceId) {
                    facilityReferralSource =
                        multiFacilityReferralSourceRevenue.find(
                            refsrc =>
                                refsrc.clientReferralSourceId ===
                                    selectedFacilityReferralSource?.clientReferralSourceId &&
                                refsrc.clientFacilityId ===
                                    facility.facilityId &&
                                refsrc.facilityLevelOfCareId ===
                                    loc.facilityLevelOfCareId
                        );
                }

                if (facilityReferralSource) {
                    facilityHasReferralSourceRevenue = true;

                    let referralRevenue =
                        facilityReferralSource.locReferralRevenue
                            ? facilityReferralSource.locReferralRevenue
                            : 0;

                    const revenueTotal: number = loc.losDays! * referralRevenue;

                    referralRevenueData.value += revenueTotal;
                }
            });
        }

        if (facilityHasReferralSourceRevenue) {
            // TODO: figure out a more saphisticated way of determining isInPatient,
            // given the different loc in different vob
            // const thresholdCode = isInpatient? ThresholdEnum.INPATREFSRCPROFITRISK : ThresholdEnum.OUTPATREFSRCPROFITRISK
            const thresholdCode = ThresholdEnum.INPATREFSRCPROFITRISK;
            let pfrConcRiskThresholds = riskThresholds?.find(
                thresh => thresh.cfgRiskThresholdCode === thresholdCode
            );
            if (pfrConcRiskThresholds) {
                switch (true) {
                    case referralRevenueData.value >
                        pfrConcRiskThresholds.lowRisk!:
                        referralRevenueData.risk = NewRiskStatus.LOW;
                        break;
                    case referralRevenueData.value <=
                        pfrConcRiskThresholds.mediumRisk!:
                        referralRevenueData.risk = NewRiskStatus.MEDIUM;
                        break;
                    case referralRevenueData.value <=
                        pfrConcRiskThresholds.highRisk!:
                        referralRevenueData.risk = NewRiskStatus.HIGH;
                        break;
                }
            }
        }

        return referralRevenueData;
    };

    calcReferralSourceRevenueEstimate = (
        facility: ImplementationFacility,
        riskAssessmentForm: RiskAssessmentForm,
        estimateBody: EstimatorBody,
        isInpatient: boolean,
        multiFacilityReferralSourceRevenue: ReferralSourceRevenue[],
        riskThresholds: FacilityRiskThreshold[]
    ): ValueRisk => {
        // Revenue Estimate from referral source data = sum of quoted LOS for each LOC * rate from "referral source revenue data" table for each LOC

        let referralRevenueData: ValueRisk = {
            value: 0,
            risk: RiskStatus.none,
        };

        let facilityHasReferralSourceRevenue: boolean = false;

        // has a referral source been selected?
        if (riskAssessmentForm.referralSource.facilityReferralSourceId) {
            const selectedReferralSourceId: number =
                riskAssessmentForm.referralSource.facilityReferralSourceId;
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            let facilityReferralSource = <ReferralSourceRevenue | undefined>{};
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            let selectedFacilityReferralSource = <
                ReferralSourceRevenue | undefined
            >{};

            estimateBody.selectedLevelsOfCare?.forEach(loc => {
                if (
                    facility.facilityId ===
                    riskAssessmentForm.facility.facilityId
                ) {
                    facilityReferralSource =
                        multiFacilityReferralSourceRevenue.find(
                            refsrc =>
                                refsrc.facilityReferralSourceId ===
                                    selectedReferralSourceId &&
                                refsrc.clientFacilityId ===
                                    facility.facilityId &&
                                refsrc.facilityLevelOfCareId ===
                                    loc.facilityLevelOfCareId
                        );
                } else {
                    selectedFacilityReferralSource =
                        multiFacilityReferralSourceRevenue.find(
                            refsrc =>
                                refsrc.facilityReferralSourceId ===
                                selectedReferralSourceId
                        );
                    if (
                        selectedFacilityReferralSource?.clientReferralSourceId
                    ) {
                        facilityReferralSource =
                            multiFacilityReferralSourceRevenue.find(
                                refsrc =>
                                    refsrc.clientReferralSourceId ===
                                        selectedFacilityReferralSource?.clientReferralSourceId &&
                                    refsrc.clientFacilityId ===
                                        facility.facilityId &&
                                    refsrc.facilityLevelOfCareId ===
                                        loc.facilityLevelOfCareId
                            );
                    }
                }

                if (facilityReferralSource) {
                    facilityHasReferralSourceRevenue = true;
                    let ratesLos: RatesLos = getRateAndLosFromQuoteMethod(
                        loc,
                        estimateBody.quoteMethod!,
                        isInpatient
                    );
                    let referralRevenue =
                        facilityReferralSource.locReferralRevenue
                            ? facilityReferralSource.locReferralRevenue
                            : 0;
                    const revenueTotal: number =
                        ratesLos.losDays! * referralRevenue;
                    referralRevenueData.value += revenueTotal;
                }
            });
        }

        if (facilityHasReferralSourceRevenue) {
            const thresholdCode = isInpatient
                ? ThresholdEnum.INPATREFSRCPROFITRISK
                : ThresholdEnum.OUTPATREFSRCPROFITRISK;
            let pfrConcRiskThresholds = riskThresholds?.find(
                thresh => thresh.cfgRiskThresholdCode === thresholdCode
            );
            if (pfrConcRiskThresholds) {
                switch (true) {
                    case referralRevenueData.value >
                        pfrConcRiskThresholds.lowRisk!:
                        referralRevenueData.risk = RiskStatus.low;
                        break;
                    case referralRevenueData.value <=
                        pfrConcRiskThresholds.mediumRisk!:
                        referralRevenueData.risk = RiskStatus.medium;
                        break;
                    case referralRevenueData.value <=
                        pfrConcRiskThresholds.highRisk!:
                        referralRevenueData.risk = RiskStatus.high;
                        break;
                }
            }
        }

        return referralRevenueData;
    };

    mergeRiskClassSettings = (
        coreRiskClasses: FinPayRiskClass[],
        configRiskClassSettings: ConfigRiskClassSetting[],
        clientRiskClassSettings: ClientRiskClassSettings[],
        multiFacilityRiskClassSettings: MultiFacilityRiskClassSetting[]
    ): FacilityRiskClassSettings[] => {
        let multiFacRiskClasses: FacilityRiskClassSettings[] = [];

        // this function will coalesce facility/client/config risk settings and return non-null
        function coalesce(
            facSetting: number | null | undefined,
            clientSetting: number | null | undefined,
            configSetting: number | null | undefined
        ) {
            var len = arguments.length;
            for (var i = 0; i < len; i++) {
                if (arguments[i] !== null && arguments[i] !== undefined) {
                    return arguments[i];
                }
            }
            return null;
        }

        multiFacilityRiskClassSettings?.forEach(
            (facRiskClassSetting: MultiFacilityRiskClassSetting) => {
                coreRiskClasses?.forEach((coreRiskClass: FinPayRiskClass) => {
                    let configClearedValue: number = 0;
                    let configHreValue: number = 0;
                    let clientClearedValue: number | undefined | null = null;
                    let clientHreValue: number | undefined | null = null;
                    let facilityClearedValue: number | undefined | null = null;
                    let facilityHreValue: number | undefined | null = null;
                    // get the cleared/hre config setting
                    let configSettings = configRiskClassSettings.find(
                        configrc =>
                            configrc.fpRiskClassId === coreRiskClass.riskClassId
                    );
                    if (configSettings) {
                        configClearedValue = configSettings.riskClassCleared
                            ? configSettings.riskClassCleared
                            : 0;
                        configHreValue = configSettings.riskClassHre
                            ? configSettings.riskClassHre
                            : 0;
                    }
                    // get the cleared/hre client setting
                    let clientSettings = clientRiskClassSettings.find(
                        clientrc =>
                            clientrc.riskClassId === coreRiskClass.riskClassId
                    );
                    if (clientSettings) {
                        clientClearedValue = clientSettings.riskClassCleared
                            ? clientSettings.riskClassCleared
                            : null;
                        clientHreValue = clientSettings.riskClassHre
                            ? clientSettings.riskClassHre
                            : null;
                    }
                    // get the cleared/hre facility setting
                    let multiFacilitySettings =
                        multiFacilityRiskClassSettings.find(
                            multiFacrc =>
                                multiFacrc.clientFacilityId ===
                                facRiskClassSetting.clientFacilityId
                        );
                    if (multiFacilitySettings) {
                        let facilitySettings =
                            multiFacilitySettings.facilityRiskClassSettings.find(
                                facilityrc =>
                                    facilityrc.riskClassId ===
                                    coreRiskClass.riskClassId
                            );
                        if (facilitySettings) {
                            facilityClearedValue =
                                facilitySettings.riskClassCleared
                                    ? facilitySettings.riskClassCleared
                                    : null;
                            facilityHreValue = facilitySettings.riskClassHre
                                ? facilitySettings.riskClassHre
                                : null;
                        }
                    }

                    multiFacRiskClasses.push({
                        clientFacilityId: facRiskClassSetting.clientFacilityId,
                        riskClassId: coreRiskClass.riskClassId,
                        riskClassCleared: coalesce(
                            facilityClearedValue,
                            clientClearedValue,
                            configClearedValue
                        ),
                        riskClassHre: coalesce(
                            facilityHreValue,
                            clientHreValue,
                            configHreValue
                        ),
                    });
                });
            }
        );
        return multiFacRiskClasses;
    };

    mergeReferralSourceRevenue(
        multiFacilityReferralSources: SelectedFacilityReferralSource[],
        multiFacilityReferralSourceRevenue: ReferralSourceRevenue[]
    ): ReferralSourceRevenue[] {
        let mergedReferralSourceData = new Array<ReferralSourceRevenue>();

        multiFacilityReferralSourceRevenue?.forEach(
            (refRevenue: ReferralSourceRevenue) => {
                let refRevenueCopy: ReferralSourceRevenue =
                    Utils.deepClone(refRevenue);
                let facilityReferralSource = multiFacilityReferralSources.find(
                    facref =>
                        facref.facilityReferralSourceId ===
                        refRevenue.facilityReferralSourceId
                );
                if (facilityReferralSource?.clientReferralSourceId) {
                    refRevenueCopy.clientReferralSourceId =
                        facilityReferralSource.clientReferralSourceId;
                } else {
                    refRevenueCopy.clientReferralSourceId = null;
                }
                mergedReferralSourceData.push(refRevenueCopy);
            }
        );

        return mergedReferralSourceData;
    }

    findInvalidProperties = (
        properties: Record<string, any>,
        requiredProperties: string[],
        validator: (value: any) => boolean
    ): [string[], boolean] => {
        const listOfMissingOrInvalidProperties: string[] =
            requiredProperties.filter(prop => {
                const value = properties[prop];
                return !validator(value);
            });

        if (listOfMissingOrInvalidProperties.length > 0) {
            return [listOfMissingOrInvalidProperties, true];
        } else {
            return [listOfMissingOrInvalidProperties, false];
        }
    };

    generateMessage = (
        missingProperties: string[],
        prettyPropertyMapping: Record<string, string>,
        messageStart: string = ''
    ) => {
        missingProperties.forEach((prop: string, index: number) => {
            const string = prettyPropertyMapping[prop];

            if (string) {
                messageStart += `${index === 0 ? '' : ','} ${string}`;
            }
        });

        return messageStart;
    };

    isValidNumber = (value: number | undefined | null) => {
        if (value === null || value === undefined) return false;

        if (typeof value === 'string') value = Number(value);

        if (isNaN(value)) return false;

        if (value < 0) return false;

        return true;
    };
}

export const riskAssessmentUtils = new RiskAssessmentUtils();
